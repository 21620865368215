import React from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { USER_TOKEN_COOKIE_NAME } from '../constants/user';
import SEOHead from '@ui-kit/components/SEOHead';
import dynamicLoader from 'next/dynamic';

const PersonalLists = dynamicLoader(() => import('components/pages/main/myLists/MyLists'));
const Interests = dynamicLoader(() => import('components/pages/main/interests/Interests'));
const MyGifts = dynamicLoader(() => import('components/pages/main/gifts/MyGifts'));

// const Recommendations = dynamic(() => import('components/pages/main/recommendations/Recommendations'));

function MainPage({ userToken }) {
  return (
    <>
      <SEOHead
        title="Здесь можно cоздать вишлист онлайн, список подарков, покупок, фильмов или товаров"
        description="Это для меня! Бесплатный вишлист на день рождения или любой праздник. Бронируйте желания друзей и составляйте списки своих."
      />
      <div className="flex flex-col items-center justify-center gap-y-16">
        <PersonalLists userToken={userToken} />
        <MyGifts userToken={userToken} />
        <Interests userToken={userToken} />
        {/*<Recommendations />*/}
      </div>
    </>
  );
}

export async function getServerSideProps(context) {
  const { locale, req } = context;
  const token = req?.cookies?.[USER_TOKEN_COOKIE_NAME] ?? '';
  return {
    props: {
      locale,
      userToken: token,
      ...(await serverSideTranslations(locale, ['common', 'mainPage'])),
    },
  };
}

export default MainPage;
